/******* screen-large.less *******/
/******* mixins.less 2013-1-16 *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
/******* hide-mobile.less 2013-1-16 *******/
.cb-mobile-access,
.cb-mobile-navigation,
.body-mobile {
  display: none;
}
.cb-mobile-navigation {
  opacity: 0;
  -o-transition: none;
  -moz-transition: none;
  -webkit-transition: none;
  transition: none;
}
.cb-mobile-invisible,
.body-non-mobile {
  display: block;
}
/******* navigation-large.less 2013-1-16 *******/
.cb-mobile-navigation {
  display: none;
}
.navi,
.navi > .item,
.dock {
  display: block;
  float: left;
}
.menu {
  display: block;
}
/* LEVEL 1 */
div.sub1 {
  width: 100%;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid #5965ac;
}
.sub1 > .item {
  width: 14.28571429%;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  border-right: 1px solid #5965ac;
}
.sub1 > .item.exit {
  border-right: none;
}
#view .sub1 > .item.init {
  display: none;
}
div.sub1 .menu {
  text-align: center;
  font-size: 23px;
  line-height: 28px;
  -o-transition: all 0.218s;
  -moz-transition: all 0.218s;
  -webkit-transition: all 0.218s;
  transition: all 0.218s;
  text-decoration: none;
  padding: 30px 10px;
  background: #fff;
}
@media (max-width: 1300px) {
  div.sub1 .menu {
    font-size: 16px;
  }
}
div.sub1 .menu:hover {
  background: #5965ac;
  color: #fff;
}
div.sub1 a.path {
  background: #5965ac;
  color: #fff;
}
#services {
  position: absolute;
  right: 35px;
  bottom: 20px;
  font-size: 12px;
  color: #7074b2;
}
#services .meta {
  font-size: 12px;
  color: #7074b2;
  margin: 0 5px;
  text-decoration: none;
}
#services .meta:hover,
#services .meta:focus {
  color: #fff;
}
/******* layout-large.less 2013-1-16 *******/
.desk {
  max-width: 100%;
}
.desk.small {
  max-width: 96%;
}
.open-navi {
  display: none;
}
.cb-home {
  margin-top: 122px;
  width: 316px;
}
.cb-page-layout1 .cb-page-title {
  margin-top: 44px;
}
.cb-page.cb-page-layout1 {
  min-height: 100vh;
  overflow: hidden;
}
.cb-navi-sticky {
  top: 0;
  display: block;
}
.cb-page-first {
  margin-top: 88px;
}
#root .cb-page-layout3 .main .pict {
  margin: 0 auto;
  float: none;
  max-width: 316px;
}
.cb-parallax > .slide {
  height: 140%;
  left: -20%;
  top: -20%;
  width: 140%;
}
.cb-page-layout2 .cb-expo,
.cb-page-layout3 .cb-expo {
  float: left;
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  width: 100%;
}
.cb-page-layout2 .cb-slides,
.cb-page-layout3 .cb-slides {
  bottom: 0;
  left: 0;
  max-height: none !important;
  max-width: none !important;
  position: absolute;
  right: 0;
  top: 0;
}
.cb-page-layout2 .cb-slides img,
.cb-page-layout3 .cb-slides img {
  width: 100% !important;
}
.cb-page-layout1 .cb-slides {
  min-height: 100vh !important;
}
.cb-page-layout1 .cb-slides img {
  min-height: 100vh;
}
#mobile-navi {
  display: none;
}
.area {
  width: 100%;
}
.area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area h2,
.area .foot {
  margin-right: 1.64203612%;
  margin-left: 1.64203612%;
}
.area .part,
.area > .grid table {
  margin-right: 1.64203612%;
  margin-left: 1.64203612%;
  width: 96.71592775%;
}
.area .tiny {
  width: 46.71592775%;
}
.area > .slim .part,
.area > .slim.grid table {
  width: 96.71592775%;
}
.cb-page-layout1 .main,
.cb-page-layout3 .main {
  width: 100%;
}
.cb-page-layout1 .main > .unit,
.cb-page-layout3 .main > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-page-layout1 .main h2,
.cb-page-layout3 .main h2,
.cb-page-layout1 .main .foot,
.cb-page-layout3 .main .foot {
  margin-right: 0%;
  margin-left: 0%;
}
.cb-page-layout1 .main .part,
.cb-page-layout3 .main .part,
.cb-page-layout1 .main > .grid table,
.cb-page-layout3 .main > .grid table {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-page-layout1 .main > .slim .part,
.cb-page-layout3 .main > .slim .part,
.cb-page-layout1 .main > .slim.grid table,
.cb-page-layout3 .main > .slim.grid table {
  width: 100%;
}
.unit.form .tile .name,
.unit a.capt {
  float: left;
  width: 33%;
}
.unit.form .tile .chop,
.unit.form .tile textarea,
.unit.form .tile input.text,
.unit.form .tile .ctrl,
.unit input.capt {
  float: right;
  width: 63%;
}
/*.unit {
  margin-top: 10px;
  margin-bottom: 10px;
}*/
.cb-page-layout1 .main {
  margin-top: 60px;
}
.cb-page-layout1 h1 {
  font-size: 31px;
}
#disp {
  left: 50%;
  margin-left: -175px;
  width: 350px;
  font-size: 15px;
  font-size: 1.5rem;
  top: 10% !important;
}
#disp.zoom {
  left: 50%;
  margin-left: -385px;
  width: 770px;
}
#disp.tube {
  left: 50%;
  margin-left: -330px;
  width: 660px;
}
#disp.site > .body {
  overflow: auto;
  max-height: 400px;
}
/*#disp.site li,
div.cb-sitemap li {
  .font-size(14); 
}*/
h1,
h2 {
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}
/*# sourceMappingURL=./screen-large.css.map */